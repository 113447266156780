import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cookie from "react-cookies";
import { getOwnUserData, login, logout, register } from "./userAPI";

const userCookie = cookie.load("c_user") || null;
// const roles = userCookie ? userCookie.roles : null;

const initialState = {
  loading: false,
  message: null,
  error: null,
  user: userCookie,
  userData: null,
  registered: false,
  // isAdmin: roles ? roles.includes("ADMIN") : false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (params, thunkAPI) => {
    const response = await register(
      params.first_name,
      params.last_name,
      params.username,
      params.email,
      params.password
    );
    return { status: response.status, data: await response.json() };
  }
);

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (params, thunkAPI) => {
    const response = await login(params.username, params.password);
    // The value we return becomes the `fulfilled` action payload
    return { status: response.status, data: await response.json() };
  }
);

export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async () => {
    const response = await getOwnUserData();
    // The value we return becomes the `fulfilled` action payload
    return { status: response.status, data: await response.json() };
  }
);

export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  const response = await logout();
  // The value we return becomes the `fulfilled` action payload
  return { status: response.status, data: await response.json() };
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserState(state) {
      state.registered = false;
      state.user = null;
      state.userData = null;
      cookie.remove("c_user");
      state.loading = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.status === 201) {
          state.message = payload.data.message;
          state.registered = true;
        } else state.error = payload.data.detail;
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state) => {
        state.loading = false;
        state.error = "Something went wrong! Please try again later.";
      });

    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.status === 200) {
          state.user = payload.data.id;
          cookie.save("c_user", payload.data.id, {
            path: "/",
          });
        } else if (payload.status === 401 || payload.status === 400)
          state.error = payload.data.detail;
        else state.error = "Something went wrong! Please try again later.";
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state) => {
        state.loading = false;
        state.error = "Something went wrong! Please try again later.";
      });

    builder
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.status === 200) {
          state.user = null;
          state.userData = null;
          cookie.remove("c_user");
          state.loading = false;
          state.message = payload.data.message;
        }
      })
      .addCase(logoutUser.rejected, (state) => {
        state.loading = false;
        state.error = "Something went wrong! Please try again later.";
      });

    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.status === 200) {
          state.userData = payload.data;
        } else if (payload.status === 401 || payload.status === 400) {
          state.user = null;
          state.userData = null;
          cookie.remove("c_user");
          state.loading = false;
          state.message =
            payload.status === 401
              ? "Your session is expired!"
              : payload.data.detail;
        } else state.error = "Something went wrong! Please try again later.";
        state.loading = false;
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.loading = false;
        state.error = "Something went wrong! Please try again later.";
      });
  },
});

export const { clearUserState } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state) => state.user.user;
export const selectUserData = (state) => state.user.userData;
export const selectLoading = (state) => state.user.loading;
// export const selectIsAdmin = (state) => state.user.isAdmin;
export const selectMessage = (state) => state.user.message;
export const selectError = (state) => state.user.error;
export const selectRegistered = (state) => state.user.registered;

export default userSlice.reducer;
