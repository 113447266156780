import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { create, getById, getOwn } from "./blogAPI";

const initialState = {
  loading: false,
  message: null,
  error: null,
  blogs: [],
  selectedBlog: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const createBlog = createAsyncThunk(
  "blog/createBlog",
  async (params, thunkAPI) => {
    const response = await create(params.name, params.description);
    return { status: response.status, data: await response.json() };
  }
);

export const getBlogById = createAsyncThunk(
  "blog/getBlogById",
  async (blogId) => {
    const response = await getById(blogId);
    return { status: response.status, data: await response.json() };
  }
);

export const getOwnBlogs = createAsyncThunk("blog/getOwnBlogs", async () => {
  const response = await getOwn();
  return { status: response.status, data: await response.json() };
});

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    clearBlogState(state) {
      state.blogs = [];
      state.selectedBlog = null;
      state.loading = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.selectedBlog = null;
      })
      .addCase(createBlog.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.status === 201) {
          state.selectedBlog = payload.data;
        } else state.error = payload.data.detail;
        state.loading = false;
      })
      .addCase(createBlog.rejected, (state) => {
        state.loading = false;
        state.error = "Something went wrong! Please try again later.";
      });

    builder
      .addCase(getBlogById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.selectedBlog = null;
      })
      .addCase(getBlogById.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.status === 200) {
          state.selectedBlog = payload.data;
        } else state.error = "Something went wrong! Please try again later.";
        state.loading = false;
      })
      .addCase(getBlogById.rejected, (state) => {
        state.loading = false;
        state.error = "Something went wrong! Please try again later.";
      });

    builder
      .addCase(getOwnBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(getOwnBlogs.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.status === 200) {
          state.blogs = payload.data;
        } else state.error = "Something went wrong! Please try again later.";
        state.loading = false;
      })
      .addCase(getOwnBlogs.rejected, (state) => {
        state.loading = false;
        state.error = "Something went wrong! Please try again later.";
      });
  },
});

export const { clearBlogState } = blogSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBlogs = (state) => state.blog.blogs;
export const selectSelectedBlog = (state) => state.blog.selectedBlog;
export const selectLoading = (state) => state.blog.loading;
export const selectMessage = (state) => state.blog.message;
export const selectError = (state) => state.blog.error;

export default blogSlice.reducer;
