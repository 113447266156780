import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Comments = () => {
  return (
    <Box>
      <Typography variant="h4">Comments</Typography>
    </Box>
  );
};
export default Comments;
