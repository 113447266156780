import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Statistics = () => {
  return (
    <Box>
      <Typography variant="h4">Statistics</Typography>
    </Box>
  );
};
export default Statistics;
